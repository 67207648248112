import * as React from 'react'

import { cn, useIsPending } from '#app/utils/misc.tsx'

export type SubmitButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>

const SubmitButton = React.forwardRef<HTMLButtonElement, SubmitButtonProps>(
  ({ type = 'submit', title, className, ...props }) => {
    const isPending = useIsPending()
    const buttonColor = isPending ? 'btn-light' : 'btn-primary'
    const finalClassName = cn('btn btn-block', buttonColor, className)

    return (
      <button
        type={type}
        disabled={isPending}
        className={finalClassName}
        {...props}
      >
        {isPending ? (
          <i className="bx bx-loader bx-spin align-middle" />
        ) : (
          title
        )}
      </button>
    )
  },
)

SubmitButton.displayName = 'Button'

export { SubmitButton }
